import img from "../../images/lavaggio_moquette.png";
import Service from "../../components/Service";
import {pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <Banner pageName="Trattamento Pavimentazioni " prepageName="Servizio" postpageName="Trattamento pavimenti e Lavaggio Moquette"/>
      <Service
        title="Trattamento Pavimenti e lavaggio Moquette"
        text="Non vuoi rischiare di rovinare la tua moquette o il tuo pavimento? Al lavaggio e al trattamento con prodotti specifici ci pensiamo noi di Manutenpul."
        img={img}
      >
      <br />
      <br />
      I professionisti Manutenpul hanno
      <lu>
        <li>Anni di esperienza</li>
        <li>Referenziati</li>
        <li>Certificati da noi</li>
      </lu>
      <br />
      <br />
      Con Manutenpul potraI:
      <lu>
        <li>Eliminare la polvere dai tappeti</li>
        <li>Pulire in profondità i pavimenti, eliminando lo sporco più difficile senza per questo rovinare le superfici.</li>
        <li>Ci occupiamo di ogni specifico trattamento della pavimentazione, per ogni tipologia, anche per moquette e tappetti.</li>
        <li>Manutenpul sarà a vostra disposizione </li>
      </lu>
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
